<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :color="color"
    :fill="fill"
    :height="height"
    :width="width"
    :viewBox="`0 0 ${widthViewBox} ${heightViewBox}`">
    <path
      d="M11 13L19 7L11 1"
      stroke="#242424" />
    <path
      d="M19 7H0"
      stroke="#242424" />
  </svg>
</template>
 
<script>
export default {
  name: 'ArrowRightIcon',
  props: {    
    width: {
      type: String,
      default: '20',
    },
    height: {
      type: String,
      default: '14',
    },
    widthViewBox: {
      type: String,
      default: '20',
    },
    heightViewBox: {
      type: String,
      default: '14',
    },
    fill: {
      type: String,
      default: 'none',
    },
    color: {
      type: String,
      default: '#24242',
    },
  }  
};
</script>